<template>
  <vs-sidebar
    spacer
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="meeting-data-sidebar items-no-padding"
    :class="{ 'meeting-data-sidebar--mobile': isMobile }"
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center px-6 meeting-data-sidebar__header" :class="{ 'justify-between': !isMobile }">
      <button v-if="isMobile" class="meeting-data-sidebar__button-back" @click.prevent="close">
        <arrow-icon :color="'#275D73'" :direction="'left'" :width="18" :height="28" @click="close" />
      </button>

      <h4 class="meeting-data-sidebar__title" :class="{ 'ml-6 pl-2': isMobile }">{{ isAddAction ? $t('vue.addMeeting') : $t('vue.meeting') }}</h4>

      <div v-if="!isMobile" style="display: flex; flex-direction: row">
        <vs-button radius color="dark" size="large" type="flat" icon="close" @click.stop="close" />
      </div>
    </div>

    <vs-divider v-if="!isMobile" class="mb-0" />

    <vx-card class="card-sidebar">
      <div v-if="meetingData && meetingData.visitorScore && meetingData.isVegaWidgetEnabled" class="card-sidebar--visitor-score">
        <VisitorStatusIconWithText :score="meetingData.visitorScore" :online="true" :isVegaWidgetEnabled="meetingData.isVegaWidgetEnabled" />
      </div>

      <div>
        <p>{{ $t('vue.name') }}:</p>

        <vs-input
          name="name"
          class="w-full mt-1 mb-2"
          :data-vv-as="$t('vue.name')"
          :disabled="!isMeetingCreatedByAgent"
          v-validate="'required'"
          v-model="name"
        />

        <span class="w-full text-danger text-sm" v-show="errors.has($t('inputs.title'))">{{ errors.first($t('inputs.title')) }}</span>
      </div>

      <template v-if="!isMeetingCreatedByAgent && dateBookedFormatter">
        <p :class="{ 'mt-5': isMobile }">{{ $t('vue.appointmentDate') }}:</p>

        <vs-input disabled class="w-full mt-1 mb-2" v-model="dateBookedFormatter" />
      </template>

      <template v-else>
        <p :class="{ 'mt-5': isMobile }">{{ $t('vue.startDate') }}:</p>

        <FlatPickr class="mt-1 w-full" :key="startDateConfig.key" :config="startDateConfig" @on-change="onChangeStartDate" v-model="dateStart" />

        <p class="mt-2" :class="{ 'mt-5': isMobile }">{{ $t('vue.endDate') }}:</p>

        <FlatPickr class="mt-1 mb-2 w-full" :key="endDateConfig.key" :config="endDateConfig" v-model="dateEnd" />
      </template>

      <div v-show="isMeetingCreatedByAgent">
        <p :class="{ 'mt-5': isMobile }">{{ $t('inputs.inviteParticipantsByEmail') }}:</p>

        <vs-row class="mb-3">
          <vs-col>
            <vs-chips
              v-show="isMeetingCreatedByAgent"
              color="rgb(145, 32, 159)"
              icon="icon-mail"
              icon-pack="feather"
              :placeholder="$t('vue.enterRecipients')"
              v-model="recipients"
              v-validate="'email'"
              :name="$t('inputs.email')"
              class="mt-1 w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg"
              size="large"
            >
              <vs-chip :key="recipient" @click="remove(recipient)" v-for="recipient in recipients" closable>
                <vs-avatar icon="mail" />
                {{ recipient }}
              </vs-chip>
            </vs-chips>
          </vs-col>
        </vs-row>

        <span class="w-full text-danger text-sm" v-show="errors.has($t('inputs.email'))">{{ errors.first($t('inputs.email')) }}</span>
      </div>

      <template v-if="!isMeetingCreatedByAgent">
        <p :class="{ 'mt-5': isMobile }">{{ $t('vue.email') }}:</p>

        <vs-input class="w-full mt-1 mb-2" :disabled="!isMeetingCreatedByAgent" v-model="email" />
      </template>

      <template>
        <p :class="{ 'mt-5': isMobile }">{{ $t('inputs.message') }}:</p>

        <vs-textarea class="mt-1 mb-2 w-full custom-textarea" height="120px" :disabled="!isMeetingCreatedByAgent" v-model="message" />
      </template>

      <template v-if="!isAddAction && meetingData">
        <p :class="{ 'mt-5': isMobile }">{{ $t('vue.action') }}:</p>

        <div class="mt-1">
          <CellRendererActions
            :params="{ data: meetingData }"
            :isMeetingCreatedByAgent="isMeetingCreatedByAgent"
            :isAddAction="isAddAction"
            @open-save-dialog="isOpenedSaveDialog = true"
          />
        </div>
      </template>

      <template v-if="!(!isAddAction && meetingData) && isMeetingCreatedByAgent">
        <vs-button class="mt-4 mb-base" color="primary" type="filled" @click="isOpenedSaveDialog = true">
          {{ isAddAction ? $t('vue.addMeeting') : $t('vue.save') }}
        </vs-button>
      </template>
    </vx-card>
    <vs-prompt
      :title="$t('modals.createMeeting')"
      :disabled="activeUserInfo.role !== 'admin'"
      buttons-hidden
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="isOpenedSaveDialog"
    >
      <div class="con-exemple-prompt">
        <p class="mb-3">
          <strong>
            {{ recipients && recipients.length > 0 ? $t('vue.shouldTheMessageBeSentToTheParticipants') : $t('vue.youDidNotEnterAnyParticipant') }}
          </strong>
        </p>
        {{ recipients && recipients.length > 0 ? '' : $t('vue.youDidNotEnterAnyParticipantQuestion') }}

        <vs-divider />

        <template v-if="recipients && recipients.length > 0">
          <div class="flex justify-end">
            <vs-button color="#f05541" type="filled" class="mr-2" @click="createMeeting(true)">
              {{ $t('vue.yes') }}
            </vs-button>

            <vs-button v-if="recipients && recipients.length > 0" type="flat" color="primary" @click="createMeeting(false)" class="meeting-cloose-btn">
              {{ $t('vue.no') }}
            </vs-button>
          </div>
        </template>

        <template v-else>
          <div class="flex justify-end">
            <vs-button color="#f05541" type="filled" class="mr-2" @click="createMeeting(false)">
              {{ $t('vue.yes') }}
            </vs-button>

            <vs-button type="flat" @click="onCloseModal" color="primary" class="meeting-close-btn">
              {{ $t('vue.no') }}
            </vs-button>
          </div>
        </template>
      </div>
    </vs-prompt>
  </vs-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'

import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import ArrowIcon from '../../components/icons/ArrowIcon'
import VisitorStatusIconWithText from '@/components/vega/VisitorStatusIconWithText.vue'

export default {
  components: {
    FlatPickr,
    CellRendererActions,
    ArrowIcon,
    VisitorStatusIconWithText
  },

  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },

    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dateFormatForPickers: 'YYYY-MM-DD HH:mm',

      startDateConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        minDate: '',
        time_24hr: true
      },

      endDateConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        minDate: '',
        time_24hr: true
      },

      dateStart: null,
      dateEnd: null,

      isOpenedSaveDialog: false,

      action: null,
      meetingData: null,
      name: '',
      email: '',
      date: '',
      recipients: [],
      message: ''
    }
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },

      set(val) {
        if (!val) {
          this.$emit('closeAppointmentsSidebar')
        }
      }
    },

    disabledDates() {
      return new Date(dayjs().startOf('day'))
    },

    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },

    dateBookedFormatter() {
      if (!this.meetingData || !this.meetingData.date) {
        return null
      }

      if (this.meetingData.date.seconds) {
        return dayjs(this.meetingData.date.seconds).format('LLL')
      }

      return dayjs(this.meetingData.date).format('LLL')
    },

    isAddAction() {
      return this.action === 'add'
    },

    isMeetingCreatedByAgent() {
      return Boolean(this.meetingData && this.meetingData.recipients) || this.action === 'add'
    }
  },

  created() {
    this.$serverBus.$on('set-meeting-data', this.setMeetingDataListener)

    this.setPickerTimeFormat()
  },

  beforeDestroy() {
    this.$serverBus.$off('set-meeting-data')
  },

  watch: {
    '$i18n.locale'() {
      this.setPickerTimeFormat()
    }
  },

  methods: {
    onCloseModal() {
      this.isOpenedSaveDialog = false
    },
    async clearData() {
      this.meetingData = null
      this.action = null
      this.date = ''
      this.name = ''
      this.email = ''
      this.message = ''

      this.startDateConfig.minDate = ''
      this.endDateConfig.minDate = ''
      this.dateStart = null
      this.dateEnd = null

      if (this.recipients && this.recipients.length > 0) {
        this.recipients = []
      }

      const result = await this.$validator.validateAll()

      if (!result) {
        this.$nextTick(() => {
          this.errors.clear()
          this.$validator.reset()
        })
      }
    },

    remove(item) {
      const recipients = [...this.recipients]

      recipients.splice(recipients.indexOf(item), 1)

      this.recipients = recipients
    },

    close() {
      this.clearData()

      this.isSidebarActiveLocal = false
    },

    setPickerTimeFormat() {
      const keys = ['startDateConfig', 'endDateConfig']
      const is24hrFormat = this.$i18n.locale === 'de'

      keys.forEach((item) => {
        this[item].time_24hr = is24hrFormat
        this[item].key = Math.random().toString(36).substring(2, 15)
      })
    },

    onChangeStartDate(selectedDates) {
      const newStartDate = dayjs(selectedDates[0])
      const newMinEndDate = newStartDate.add(15, 'minute')
      const newMinEndDateFormattedString = newMinEndDate.format(this.dateFormatForPickers)

      this.endDateConfig.minDate = newMinEndDateFormattedString

      if (this.dateEnd) {
        const currentEndDate = dayjs(this.dateEnd)

        if (newStartDate.isAfter(currentEndDate) || newStartDate.diff(currentEndDate, 'minute') > -15) {
          this.dateEnd = newStartDate.add(30, 'minute').format(this.dateFormatForPickers)
        }
      }
    },

    setMeetingDataListener(value) {
      if (!value) {
        this.clearData()

        return
      }

      const defaultDate = new Date()
      const coff = 1000 * 60 * 5
      const roundedUpDate = new Date(Math.ceil(defaultDate / coff) * coff)
      const modifiedDate = dayjs(roundedUpDate)
      const { meeting, action, date, allDay } = value

      if (meeting) {
        this.meetingData = meeting

        this.name = this.meetingData.name
        this.email = this.meetingData.email
        this.recipients = this.meetingData.recipients || []
        this.message = this.meetingData.message ? this.meetingData.message.replace(/<br>/g, '\n') : ''

        const dateStart = dayjs(this.meetingData.start).format(this.dateFormatForPickers)
        const dateEnd = dayjs(this.meetingData.end).format(this.dateFormatForPickers)

        if (dayjs().isAfter(dayjs(meeting.date))) {
          this.startDateConfig.minDate = dateStart
        } else {
          this.startDateConfig.minDate = modifiedDate.format(this.dateFormatForPickers)
        }

        this.dateStart = dateStart
        this.dateEnd = dateEnd
      } else if (action === 'add') {
        this.action = action

        if (date) {
          const dateStart = dayjs(date).format(this.dateFormatForPickers)

          if (dayjs().isAfter(dayjs(date))) {
            this.startDateConfig.minDate = dateStart
          } else {
            this.startDateConfig.minDate = modifiedDate.format(this.dateFormatForPickers)
          }

          this.dateStart = dateStart

          if (!allDay) {
            this.dateEnd = dayjs(date).add(30, 'minute').format(this.dateFormatForPickers)
          }
        } else {
          const dateStart = modifiedDate.format(this.dateFormatForPickers)

          this.startDateConfig.minDate = dateStart
          this.dateStart = dateStart
          this.dateEnd = modifiedDate.add(30, 'minute').format(this.dateFormatForPickers)
        }
      }
    },

    async createMeeting(isMessageToBeSent = false) {
      const result = await this.$validator.validateAll()

      if (!result) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.errors.first('name'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return
      }

      const date = this.dateStart
      const diffInMinutes = dayjs(this.dateEnd).diff(dayjs(this.dateStart), 'minute')

      let meetingData = {
        isMessageToBeSent,
        agentId: this.activeUserInfo.uid,
        companyId: this.activeUserInfo.company,
        name: this.name,
        agentEmail: this.activeUserInfo.syncedEmail || this.activeUserInfo.email,
        date: new Date(date).getTime(),
        message: this.message ? this.message.replace(/\n/g, '<br>') : '',
        duration: Number(diffInMinutes),
        displayName: this.activeUserInfo.displayName,
        recipients: this.recipients,
        companyName: this.company && this.company.name,
        locale: this.$i18n.locale
      }

      if (this.isAddAction) {
        meetingData = {
          ...meetingData,
          type: 'online-meeting',
          created: new Date().getTime(),
          createdBy: this.activeUserInfo.uid,
          emailSubject: this.$i18n.t('vue.joinMeeting')
        }

        const meeting = await this.$db.collection('meetings').add(meetingData)

        if (meeting) {
          const meetingInfo = {
            meetingId: meeting.id,
            env: process.env.NODE_ENV
          }

          const encryptedMeetingInfo = window.btoa(unescape(encodeURIComponent(JSON.stringify(meetingInfo))))

          await this.$db.collection('meetings').doc(meeting.id).set({ encryptedMeetingInfo }, { merge: true })

          this.$vs.notify({
            title: this.$i18n.t('vue.success'),
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-mail'
          })
        }
      } else {
        meetingData = {
          ...meetingData,
          modifiedDate: new Date().getTime()
        }

        try {
          await this.$db.collection('meetings').doc(this.meetingData.id).set(meetingData, { merge: true })

          this.$vs.notify({
            title: this.$i18n.t('vue.changesSavedSuccessfully'),
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-mail'
          })
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error)
        }
      }

      this.clearData()

      this.isOpenedSaveDialog = false

      this.$serverBus.$emit('add-meeting')

      setTimeout(this.close, 200)
    },

    async sendEmailInvitation(meetingId, date, duration, message) {
      await this.$vs.loading()

      const result = await this.$validator.validateAll()

      if (result) {
        const sendCobrowsingEmail = this.$functions.httpsCallable('sendCobrowsingEmail')

        const meetingInfo = {
          meetingId,
          env: process.env.NODE_ENV
        }

        const encryptedMeetingInfo = window.btoa(unescape(encodeURIComponent(JSON.stringify(meetingInfo))))

        try {
          await sendCobrowsingEmail({
            date,
            duration,
            encryptedMeetingInfo,
            message,
            displayName: this.activeUserInfo.displayName ? this.activeUserInfo.displayName : `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
            locale: this.$i18n.locale,
            recipients: this.recipients.join(','),
            emailSubject: this.$i18n.t('vue.joinMeeting'),
            userId: this.activeUserInfo.uid,
            userEmail: this.activeUserInfo.email,
            companyId: this.activeUserInfo.company,
            companyName: this.company && this.company.name
          })

          this.$vs.notify({
            title: this.$i18n.t('vue.emailInvitationSent'),
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-mail'
          })
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error)
        }
      }

      this.$vs.loading.close()
    },

    copyTextToClipboard(id) {
      /* Get the text field */
      const copyText = document.getElementById(id)
      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy')

      this.$vs.notify({
        title: this.$i18n.t('vue.copiedLink'),
        color: 'primary',
        iconPack: 'feather',
        icon: 'icon-clipboard'
      })
    }
  }
}
</script>

<style lang="scss">
.meeting-close-btn {
  .vs-button--text {
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
<style lang="scss" scoped>
.card-sidebar {
  height: calc(100% - 80px);
  overflow-y: auto;
  scrollbar-width: thin;

  &--visitor-score {
    width: fit-content;
    margin-bottom: 20px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(171, 171, 171);
    border-radius: 6px;
  }
}

.header-text {
  font-weight: bold;
}

.meeting-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__button-back {
    background: none;
    border: none;
  }
}

.scroll-area--data-list-add-new {
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>

<style lang="scss">
.flatpickr-calendar .flatpickr-monthDropdown-months {
  border: none !important;
}

.pa-bk-secondary {
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
  color: #fff !important;
}

.meeting-data-sidebar {
  .vs-sidebar {
    max-width: 400px !important;

    @media only screen and (max-width: 1024px) {
      max-width: 100% !important;
    }
  }

  &--mobile {
    .vs-sidebar {
      width: 100% !important;
      height: 100% !important;
    }

    .flatpickr-input {
      white-space: nowrap;
    }

    .meeting-data-sidebar {
      &__title {
        color: rgb(38, 38, 41);
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
      }
    }

    .card-sidebar {
      box-shadow: none !important;
    }

    p {
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.vs-popup {
  width: 460px !important;
}

.custom-vue-timepicker-wrapper {
  .display-time {
    width: 100% !important;
    height: 38px !important;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 5px;

    &_none-shadow {
      &.disabled,
      input.display-time:disabled {
        color: inherit !important;
        background: rgb(253, 253, 253) !important;
      }
    }
  }

  .dropdown {
    ul {
      li:not([disabled]) {
        &.active,
        &.active:focus,
        &.active:hover {
          color: rgb(255, 255, 255);
          background: rgba(var(--vs-primary), 1);
        }
      }
    }
  }
}

.custom-textarea {
  border-color: rgb(228, 228, 228) !important;

  .vs-textarea {
    resize: auto !important;

    &:disabled {
      color: rgb(98, 98, 98);
      background: rgb(253, 253, 253);
    }
  }
}
</style>
