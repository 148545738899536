var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-sidebar",
    {
      staticClass: "meeting-data-sidebar items-no-padding",
      class: { "meeting-data-sidebar--mobile": _vm.isMobile },
      attrs: {
        spacer: "",
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "mt-6 flex items-center px-6 meeting-data-sidebar__header",
          class: { "justify-between": !_vm.isMobile },
        },
        [
          _vm.isMobile
            ? _c(
                "button",
                {
                  staticClass: "meeting-data-sidebar__button-back",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("arrow-icon", {
                    attrs: {
                      color: "#275D73",
                      direction: "left",
                      width: 18,
                      height: 28,
                    },
                    on: { click: _vm.close },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "h4",
            {
              staticClass: "meeting-data-sidebar__title",
              class: { "ml-6 pl-2": _vm.isMobile },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.isAddAction
                    ? _vm.$t("vue.addMeeting")
                    : _vm.$t("vue.meeting")
                )
              ),
            ]
          ),
          !_vm.isMobile
            ? _c(
                "div",
                { staticStyle: { display: "flex", "flex-direction": "row" } },
                [
                  _c("vs-button", {
                    attrs: {
                      radius: "",
                      color: "dark",
                      size: "large",
                      type: "flat",
                      icon: "close",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.close.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      !_vm.isMobile ? _c("vs-divider", { staticClass: "mb-0" }) : _vm._e(),
      _c(
        "vx-card",
        { staticClass: "card-sidebar" },
        [
          _vm.meetingData &&
          _vm.meetingData.visitorScore &&
          _vm.meetingData.isVegaWidgetEnabled
            ? _c(
                "div",
                { staticClass: "card-sidebar--visitor-score" },
                [
                  _c("VisitorStatusIconWithText", {
                    attrs: {
                      score: _vm.meetingData.visitorScore,
                      online: true,
                      isVegaWidgetEnabled: _vm.meetingData.isVegaWidgetEnabled,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("vue.name")) + ":")]),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "w-full mt-1 mb-2",
                attrs: {
                  name: "name",
                  "data-vv-as": _vm.$t("vue.name"),
                  disabled: !_vm.isMeetingCreatedByAgent,
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has(_vm.$t("inputs.title")),
                      expression: "errors.has($t('inputs.title'))",
                    },
                  ],
                  staticClass: "w-full text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first(_vm.$t("inputs.title"))))]
              ),
            ],
            1
          ),
          !_vm.isMeetingCreatedByAgent && _vm.dateBookedFormatter
            ? [
                _c("p", { class: { "mt-5": _vm.isMobile } }, [
                  _vm._v(_vm._s(_vm.$t("vue.appointmentDate")) + ":"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full mt-1 mb-2",
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.dateBookedFormatter,
                    callback: function ($$v) {
                      _vm.dateBookedFormatter = $$v
                    },
                    expression: "dateBookedFormatter",
                  },
                }),
              ]
            : [
                _c("p", { class: { "mt-5": _vm.isMobile } }, [
                  _vm._v(_vm._s(_vm.$t("vue.startDate")) + ":"),
                ]),
                _c("FlatPickr", {
                  key: _vm.startDateConfig.key,
                  staticClass: "mt-1 w-full",
                  attrs: { config: _vm.startDateConfig },
                  on: { "on-change": _vm.onChangeStartDate },
                  model: {
                    value: _vm.dateStart,
                    callback: function ($$v) {
                      _vm.dateStart = $$v
                    },
                    expression: "dateStart",
                  },
                }),
                _c(
                  "p",
                  { staticClass: "mt-2", class: { "mt-5": _vm.isMobile } },
                  [_vm._v(_vm._s(_vm.$t("vue.endDate")) + ":")]
                ),
                _c("FlatPickr", {
                  key: _vm.endDateConfig.key,
                  staticClass: "mt-1 mb-2 w-full",
                  attrs: { config: _vm.endDateConfig },
                  model: {
                    value: _vm.dateEnd,
                    callback: function ($$v) {
                      _vm.dateEnd = $$v
                    },
                    expression: "dateEnd",
                  },
                }),
              ],
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMeetingCreatedByAgent,
                  expression: "isMeetingCreatedByAgent",
                },
              ],
            },
            [
              _c("p", { class: { "mt-5": _vm.isMobile } }, [
                _vm._v(
                  _vm._s(_vm.$t("inputs.inviteParticipantsByEmail")) + ":"
                ),
              ]),
              _c(
                "vs-row",
                { staticClass: "mb-3" },
                [
                  _c(
                    "vs-col",
                    [
                      _c(
                        "vs-chips",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isMeetingCreatedByAgent,
                              expression: "isMeetingCreatedByAgent",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "email",
                              expression: "'email'",
                            },
                          ],
                          staticClass:
                            "mt-1 w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg",
                          attrs: {
                            color: "rgb(145, 32, 159)",
                            icon: "icon-mail",
                            "icon-pack": "feather",
                            placeholder: _vm.$t("vue.enterRecipients"),
                            name: _vm.$t("inputs.email"),
                            size: "large",
                          },
                          model: {
                            value: _vm.recipients,
                            callback: function ($$v) {
                              _vm.recipients = $$v
                            },
                            expression: "recipients",
                          },
                        },
                        _vm._l(_vm.recipients, function (recipient) {
                          return _c(
                            "vs-chip",
                            {
                              key: recipient,
                              attrs: { closable: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.remove(recipient)
                                },
                              },
                            },
                            [
                              _c("vs-avatar", { attrs: { icon: "mail" } }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(recipient) +
                                  "\n            "
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has(_vm.$t("inputs.email")),
                      expression: "errors.has($t('inputs.email'))",
                    },
                  ],
                  staticClass: "w-full text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first(_vm.$t("inputs.email"))))]
              ),
            ],
            1
          ),
          !_vm.isMeetingCreatedByAgent
            ? [
                _c("p", { class: { "mt-5": _vm.isMobile } }, [
                  _vm._v(_vm._s(_vm.$t("vue.email")) + ":"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full mt-1 mb-2",
                  attrs: { disabled: !_vm.isMeetingCreatedByAgent },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
              ]
            : _vm._e(),
          [
            _c("p", { class: { "mt-5": _vm.isMobile } }, [
              _vm._v(_vm._s(_vm.$t("inputs.message")) + ":"),
            ]),
            _c("vs-textarea", {
              staticClass: "mt-1 mb-2 w-full custom-textarea",
              attrs: {
                height: "120px",
                disabled: !_vm.isMeetingCreatedByAgent,
              },
              model: {
                value: _vm.message,
                callback: function ($$v) {
                  _vm.message = $$v
                },
                expression: "message",
              },
            }),
          ],
          !_vm.isAddAction && _vm.meetingData
            ? [
                _c("p", { class: { "mt-5": _vm.isMobile } }, [
                  _vm._v(_vm._s(_vm.$t("vue.action")) + ":"),
                ]),
                _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    _c("CellRendererActions", {
                      attrs: {
                        params: { data: _vm.meetingData },
                        isMeetingCreatedByAgent: _vm.isMeetingCreatedByAgent,
                        isAddAction: _vm.isAddAction,
                      },
                      on: {
                        "open-save-dialog": function ($event) {
                          _vm.isOpenedSaveDialog = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          !(!_vm.isAddAction && _vm.meetingData) && _vm.isMeetingCreatedByAgent
            ? [
                _c(
                  "vs-button",
                  {
                    staticClass: "mt-4 mb-base",
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function ($event) {
                        _vm.isOpenedSaveDialog = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isAddAction
                            ? _vm.$t("vue.addMeeting")
                            : _vm.$t("vue.save")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("modals.createMeeting"),
            disabled: _vm.activeUserInfo.role !== "admin",
            "buttons-hidden": "",
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.isOpenedSaveDialog,
          },
          on: {
            "update:active": function ($event) {
              _vm.isOpenedSaveDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "con-exemple-prompt" },
            [
              _c("p", { staticClass: "mb-3" }, [
                _c("strong", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.recipients && _vm.recipients.length > 0
                          ? _vm.$t(
                              "vue.shouldTheMessageBeSentToTheParticipants"
                            )
                          : _vm.$t("vue.youDidNotEnterAnyParticipant")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.recipients && _vm.recipients.length > 0
                      ? ""
                      : _vm.$t("vue.youDidNotEnterAnyParticipantQuestion")
                  ) +
                  "\n\n      "
              ),
              _c("vs-divider"),
              _vm.recipients && _vm.recipients.length > 0
                ? [
                    _c(
                      "div",
                      { staticClass: "flex justify-end" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#f05541", type: "filled" },
                            on: {
                              click: function ($event) {
                                return _vm.createMeeting(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.yes")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm.recipients && _vm.recipients.length > 0
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "meeting-cloose-btn",
                                attrs: { type: "flat", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.createMeeting(false)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("vue.no")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "flex justify-end" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-2",
                            attrs: { color: "#f05541", type: "filled" },
                            on: {
                              click: function ($event) {
                                return _vm.createMeeting(false)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.yes")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass: "meeting-close-btn",
                            attrs: { type: "flat", color: "primary" },
                            on: { click: _vm.onCloseModal },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.no")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }