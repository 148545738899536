var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("modals.deleteMeeting"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.removeMeeting,
          },
          on: {
            cancel: _vm.onCloseModal,
            accept: _vm.deleteMeeting,
            close: _vm.onCloseModal,
            "update:active": function ($event) {
              _vm.removeMeeting = $event
            },
          },
        },
        [
          _vm.params.data.name
            ? _c("div", { staticClass: "con-exemple-prompt" }, [
                _c("p", [_c("strong", [_vm._v(_vm._s(_vm.params.data.name))])]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("modals.deleteMeetingQuestion")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "flex",
          style: { direction: _vm.$vs.rtl ? "rtl" : "ltr" },
        },
        [
          _vm.params.data.status !== "canceled"
            ? _c(
                "vs-button",
                {
                  class: { "mr-3": _vm.isMeetingCreatedByAgent },
                  staticStyle: {
                    "white-space": "nowrap",
                    padding: "0.75rem 14px",
                  },
                  attrs: { color: "primary", icon: "link" },
                  on: { click: _vm.joinMeeting },
                },
                [_vm._v(_vm._s(_vm.$t("vue.join")))]
              )
            : _c(
                "div",
                {
                  staticClass: "mr-3 text-center",
                  staticStyle: { width: "160px", color: "rgb(240, 85, 65)" },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("info.canceled")) + "\n    "
                  ),
                ]
              ),
          _vm.isMeetingCreatedByAgent
            ? [
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-1 mr-3",
                    staticStyle: { padding: "0.75rem 14px" },
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("open-save-dialog")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isAddAction
                            ? _vm.$t("vue.addMeeting")
                            : _vm.$t("vue.save")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _c(
            "vx-tooltip",
            {
              attrs: { text: _vm.$t("info.copyMeetingLink"), position: "top" },
            },
            [
              _c("vs-button", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.meetingLink,
                    expression: "meetingLink",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.onCopy,
                    expression: "onCopy",
                    arg: "success",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:error",
                    value: _vm.onError,
                    expression: "onError",
                    arg: "error",
                  },
                ],
                staticClass: "ml-1",
                attrs: {
                  size: "medium",
                  color: "success",
                  type: "border",
                  "icon-pack": "feather",
                  icon: "icon-copy",
                },
              }),
            ],
            1
          ),
          _c("vs-button", {
            staticClass: "ml-1",
            attrs: {
              disabled:
                ["agent", "view-only"].includes(_vm.activeUserInfo.role) &&
                _vm.params.data.createdBy !== _vm.activeUserInfo.uid,
              color: "danger",
              type: "border",
              size: "medium",
              "icon-pack": "feather",
              icon: "icon-trash",
            },
            on: {
              click: function ($event) {
                return _vm.showRemoveMeetingPopup()
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }