<template>
  <div>
    <vs-prompt :title="$t('modals.deleteMeeting')" @cancel="onCloseModal" @accept="deleteMeeting" @close="onCloseModal" color="danger" :cancel-text="$t('vue.cancel')" :accept-text="$t('vue.delete')" :active.sync="removeMeeting">
      <div class="con-exemple-prompt" v-if="params.data.name">
        <p>
          <strong>{{ params.data.name }}</strong>
        </p>
        {{ $t('modals.deleteMeetingQuestion') }}
      </div>
    </vs-prompt>

    <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
      <vs-button v-if="params.data.status !== 'canceled'" style="white-space: nowrap; padding: 0.75rem 14px" color="primary" icon="link" :class="{ 'mr-3': isMeetingCreatedByAgent }" @click="joinMeeting">{{ $t('vue.join') }}</vs-button>

      <div v-else class="mr-3 text-center" style="width: 160px; color: rgb(240, 85, 65)">
        {{ $t('info.canceled') }}
      </div>

      <template v-if="isMeetingCreatedByAgent">
        <vs-button class="ml-1 mr-3" style="padding: 0.75rem 14px" color="primary" type="filled" @click="$emit('open-save-dialog')">
          {{ isAddAction ? $t('vue.addMeeting') : $t('vue.save') }}
        </vs-button>
      </template>

      <!-- <vs-button
        v-if="params.data.message && params.data.message.trim().length > 0"
        color="success"
        type="border"
        size="medium"
        icon-pack="feather"
        icon="icon-message-square"
        @click.stop="$serverBus.$emit('open-message-sidebar', params.data)"
      />

      <vs-button v-else disabled color="dark" type="border" size="medium" icon-pack="feather" icon="icon-message-square" /> -->

      <vx-tooltip :text="$t('info.copyMeetingLink')" position="top">
        <vs-button class="ml-1" size="medium" color="success" type="border" icon-pack="feather" icon="icon-copy" v-clipboard:copy="meetingLink" v-clipboard:success="onCopy" v-clipboard:error="onError"></vs-button>
      </vx-tooltip>

      <vs-button class="ml-1" :disabled="['agent', 'view-only'].includes(activeUserInfo.role) && params.data.createdBy !== activeUserInfo.uid" color="danger" type="border" size="medium" icon-pack="feather" icon="icon-trash" @click="showRemoveMeetingPopup()"></vs-button>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

import { mapGetters } from 'vuex'

export default {
  name: 'CellRendererActions',

  props: {
    params: {
      type: Object
    },
    isMeetingCreatedByAgent: {
      type: Boolean
    },
    isAddAction: {
      type: Boolean
    }
  },

  data() {
    return {
      coBrowsing: null,
      removeMeeting: false,
      url: null
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('online-meeting') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    meetingLink() {
      if (!this.HAS_ACCESS) {
        return this.$i18n.t('vue.accessDenied')
      } else {
        if (this.params.data.joinOnlineMeetingToolUrl) {
          return this.params.data.joinOnlineMeetingToolUrl
        }

        const meetingInfo = {
          meetingId: this.params.data.id,
          env: process.env.NODE_ENV
        }

        const encryptedMeetingInfo = window.btoa(unescape(encodeURIComponent(JSON.stringify(meetingInfo))))

        return `https://meet.letsconnect.at/${encryptedMeetingInfo}`
      }
    }
  },
  methods: {
    showRemoveMeetingPopup() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        this.removeMeeting = true
      }
    },
    onCloseModal() {
      this.removeMeeting = false
    },
    onCopy() {
      this.$vs.notify({
        title: this.translate('vue.success'),
        text: this.translate('vue.linkCopiedSuccessfully'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    },
    onError() {
      this.$vs.notify({
        title: this.translate('vue.error'),
        text: this.translate('vue.errorCopyingText'),
        color: 'danger',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-alert-circle'
      })
    },
    translate(code) {
      return this.$i18n.t(code)
    },

    async joinMeeting() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        if (this.params.data.joinOnlineMeetingToolUrl) {
          return window.open(this.params.data.joinOnlineMeetingToolUrl, '_blank').focus()
        }

        window.open(this.meetingLink, '_blank').focus()
      }
    },

    async deleteMeeting() {
      if (!this.HAS_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        const vm = this

        setTimeout(async () => {
          vm.removeMeeting = false
          const ref = vm.$database.ref(`/meetings/${vm.params.data.id}`)
          if (ref) {
            ref.remove()
          }
          await vm.$db.collection('meetings').doc(vm.params.data.id).delete()

          this.$serverBus.$emit('close-meeting-sidebar')
        }, 500)
      }
    }
  }
}
</script>
